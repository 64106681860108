<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <template v-for="i in fields">
          <th
            :key="i.key"
            scope="col"
            :class="i.thClass"
          >
            {{ $t(i.label) }}
          </th>
        </template>
      </tr>
    </thead>
    <draggable
      v-model="list"
      :disabled="$can('manage', 'admin')"
      tag="tbody"
      @change="$emit('change', list)"
    >
      <tr
        v-for="item in list"
        :key="`tablle${item.id}`"
      >
        <td scope="row">
          {{ item.title }}
        </td>
      </tr>
    </draggable>
  </table>
</template>

<script>
// import { BButton } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    draggable,
    // BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'title',
          key: 'title',
        },
      ],
    }
  },
}
</script>
