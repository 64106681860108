<template>
  <div>
    <b-card>
      <DraggableTableFormType
        :list="GET_ONE_COURSE.form_type"
        @change="onChangePosition"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import toast from '@/mixins/toast'
import DraggableTableFormType from './components/DraggableTableFormType.vue'

export default {
  components: {
    BCard,
    DraggableTableFormType,
  },
  directives: {
    Ripple,
  },

  mixins: [toast],
  data() {
    return {
      pageOptions: [3, 5, 10],
      params: {
        page_size: 1000,
        page: 1,
      },
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_FORM_TYPES', 'GET_ONE_COURSE']),
  },
  mounted() {
    this.FETCH_ONE_COURSE({ id: this.$route.params.id })
  },
  methods: {
    ...mapActions('courses', ['FETCH_ONE_COURSE', 'CHANGE_POSITION_FORM_TYPE']),
    updateList() {
      this.params.page = 1
      this.FETCH_FORM_TYPES(this.params)
    },
    onChangePosition(e) {
      const body = { id: this.$route.params.id, form_types_order: e.map(el => el.id) }
      console.log(body)
      this.CHANGE_POSITION_FORM_TYPE(body).then(() => {
        this.updateList()
      })
    },
  },
}
</script>

<style>

</style>
